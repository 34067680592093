<template>
  <Container theme="dark" :padding="false">
    <template v-if="checkResponseStatus && !isLoading">
      <MyCollectionItem
        v-for="(item, index) in traderRelationsDataArray"
        :key="index"
        :traderName="item.traderName"
        :signature="item.signature"
        :orderSuccess="item.orderSuccess"
        :successRate="item.successRate"
        :receiveStatusDesc="item.receiveStatusDesc"
        :alipayStatus="item.alipayStatus"
        :bankcardStatus="item.bankcardStatus"
        :wechatpayStatus="item.wechatpayStatus"
        :traderId="item.traderId"
        :remark="item.remark"
        :isAllowRechargeDesc="item.isAllowRechargeDesc"
        :isAllowWithdrawDesc="item.isAllowWithdrawDesc"
        @goToPath="goToPath"
        :class="{
          mb_6rem: traderRelationsDataArray.length > 5 && index === traderRelationsDataArray.length - 1,
        }"
      >
      </MyCollectionItem>
    </template>
    <NoData v-else-if="!checkResponseStatus && !isLoading"></NoData>
  </Container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import NoData from "@/components/NoData";
import Container from "../components/Container.vue";
import MyCollectionItem from "../components/MyCollectionItem.vue";
export default {
  components: {
    NoData,
    Container,
    MyCollectionItem,
  },
  data() {
    return {
      title: "",
    };
  },
  watch: {
    traderRelationsData: {
      handler: function(newVal) {
        this.changeSpinnerStatus(true);
        if (newVal) {
          setTimeout(() => {
            this.changeSpinnerStatus(false);
          }, 800);
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState("spinner", ["isLoading"]),
    ...mapState("trader", ["traderRelationsData"]),
    traderRelationsDataArray() {
      return this.checkResponseStatus ? this.traderRelationsData : [];
    },
    checkResponseStatus() {
      return this.traderRelationsData && this.traderRelationsData.length > 0 ? true : false;
    },
  },
  methods: {
    ...mapActions("trader", ["getTraderRelations"]),
    ...mapActions("spinner", ["changeSpinnerStatus"]),
    goToPath(traderId) {
      console.log("goToPath111");
      if (!traderId) return;
      this.$router.push({
        path: "/my/myCollection/trader",
        query: { traderId: traderId },
      });
    },
  },
  created() {
    this.getTraderRelations({
      page: 1,
      limit: 20,
    });
  },
};
</script>

<style></style>
