<template>
  <div class="bg_light" v-if="traderId" @click="goToPath">
    <div class="item border_bottom">
      <div>
        <div class="d_flex justify_content_between ">
          <div class="d_flex">
            <div class="icon">
              <img src="../../../assets/images/public/ic_my_head.webp" alt="" />
            </div>
            <div class="title tw-flex tw-items-center tw-gap-2">
              {{ traderName }}
              <div class="tw-text-gray-400 tw-text-sm" @click="unfavorite($event)">取消收藏</div>
            </div>
          </div>
          <div class="d_flex text_light">
            <div>成交 {{ orderSuccess }} | 成交率 {{ successRate }}</div>
          </div>
        </div>
        <div class="d_flex justify_content_between ">
          <div class="d_flex">
            <div class=" text_light">
              {{ signature == "" ? "无签名" : signature }}
            </div>
          </div>
          <div class="d_flex text_light">
            <div class="icon icon_sm" v-show="wechatpayStatus === 1">
              <img src="../../../assets/images/public/ic_my_money_aisle_wechat.webp" alt="" />
            </div>
            <div class="icon icon_sm" v-show="alipayStatus === 1">
              <img src="../../../assets/images/public/ic_my_money_aisle_alipay.webp" alt="" />
            </div>
            <div class="icon icon_sm" v-show="bankcardStatus === 1">
              <img src="../../../assets/images/public/ic_my_money_aisle_bank.webp" alt="" />
            </div>
          </div>
        </div>
        <div class="text_primary">
          <div class="text_light">
            充值 : <span :class="isAllowRechargeDesc == '在线' ? 'text_primary' : ''">{{ isAllowRechargeDesc }} </span> 提现 : <span>{{ isAllowWithdrawDesc }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { Message } from "element-ui";

export default {
  props: {
    traderId: {
      type: Number,
      default: 0,
    },
    traderName: {
      type: String,
      default: "",
    },
    signature: {
      type: String,
      default: "",
    },
    successRate: {
      type: String,
      default: "",
    },
    receiveStatusDesc: {
      type: String,
      default: "不在线",
    },

    isAllowRechargeDesc: {
      type: String,
      default: "不在线",
    },
    isAllowWithdrawDesc: {
      type: String,
      default: "不在线",
    },

    orderSuccess: {
      type: Number,
      default: 0,
    },
    alipayStatus: {
      type: Number,
      default: 0,
    },
    bankcardStatus: {
      type: Number,
      default: 0,
    },
    wechatpayStatus: {
      type: Number,
      default: 0,
    },
    remark: {
      type: String,
      default: "无签名",
    },
  },
  methods: {
    ...mapActions("trader", ["getTraderRelations", "deleteTraderRelations"]),

    async unfavorite(event) {
      event.stopPropagation();
      const confirmed = confirm("确定要取消收藏吗？");

      if (confirmed) {
        const r = await this.deleteTraderRelations(this.traderId);

        if (r.code == "ok")
          this.getTraderRelations({
            page: 1,
            limit: 20,
          });
      }
    },
    goToPath() {
      console.log("goToPath");
      console.log(this.isAllowRechargeDesc, this.isAllowWithdrawDesc);
      if (this.isAllowRechargeDesc == "在线" || this.isAllowWithdrawDesc == "在线") {
        this.$emit("goToPath", this.traderId);
      } else {
        Message({
          message: "对方未开启交易",
          iconClass: "x",
          center: true,
          customClass: "error_message",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bg_light {
  background: white;
  padding: 0rem 1.2rem 0rem 1.2rem;
}

.d_flex {
  display: flex;
  align-items: center;
}

.flex_column {
  flex-direction: column;
}

.justify_content_between {
  justify-content: space-between;
}

.align_items_start {
  align-items: flex-start;
  text-align: left;
}

.item {
  width: 100%;
  box-sizing: border-box;
  padding: 0.75rem 0rem;
  box-sizing: border-box;
}

.title {
  font-weight: bold;
  text-align: left;
  line-height: 1.5;
  margin-left: 0.6rem;
}

.text_light {
  font-size: 0.85rem;
  text-align: right;
  line-height: 1.5;
  color: var(--gray);
}

.text_primary {
  font-size: 0.85rem;
  text-align: right;
  line-height: 1.5;
  color: var(--orange);
}

.createdAt {
  font-size: 0.85rem;
  text-align: right;
  color: var(--gray);
  margin-left: auto;
}

.icon {
  display: block;
  width: 1.8rem;
  transform: translateY(0.1rem);

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}

.icon_sm {
  width: 1.2rem;
  margin-left: 0.35rem;
}

.border_bottom {
  border-bottom: 0.75px solid #d4d4d481;
}
</style>
